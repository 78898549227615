import React, { useContext, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import classNames from "classnames"
import Img from "gatsby-image"

import Layout from "layout/Layout"
import Container from "layout/Container"

import styles from "../../utils/medensure.module.scss"
import { isBrowser } from "../../../../services/general"
import { AppContext } from "../../../../context/AppContext"
import { generateInitialValues } from "../../../../services/context"

const UpdatedPrescriptionComplete = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const { pageContext } = props
  const { module } = pageContext
  const data = useStaticQuery(graphql`
    {
      completed: file(relativePath: { eq: "icons/email.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const completed = data.completed.childImageSharp.fluid

  let formFields = pageContext.formFields

  useEffect(() => {
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        documents: [],
        updatedPrescription: {
          ...generateInitialValues({ fields: formFields }),
          company: state?.updatedPrescription?.company || "",
          medicines: [],
          cancelledMedicines: [],
        },
        updatePersonalDetails: {
          deliveryAddress: {
            addressType: "",
            streetAddress: "",
            officeAddress: {
              label: "",
              value: "",
            },
            province: {
              label: "",
              value: "",
            },
            city: {
              label: "",
              value: "",
            },
            barangay: {
              label: "",
              value: "",
            },
            notes: "",
          },
        },
      },
    })
    if (isBrowser()) {
      sessionStorage.removeItem("medensureServices")
    }
  }, [])

  return (
    <Layout
      title={module.title || "Thank you"}
      seoTitle={module.seoTitle}
      pageContext={pageContext}
      path="/medensure"
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        <div className={classNames("columns is-align-items-center")}>
          <div className="column is-4-desktop is-12-mobile">
            <figure className={classNames("image mx-3")}>
              <Img
                fluid={completed}
                alt={`${module.name} Complete`}
                className={styles["image"]}
              />
            </figure>
          </div>
          <div className="column">
            <p className="is-size-5">
              Thank you for answering this form. We will get back to you within
              1-2 working days to confirm your details. Please send us an email
              if you have any inquiries. We look forward to serving you.
            </p>
          </div>
        </div>
        <center>
          <Link
            to={`/medensure`}
            className={classNames(
              "button px-2 px-2-mobile mr-1 mr-1-mobile",
              styles["button_next"]
            )}
          >
            Back to Home Page
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default UpdatedPrescriptionComplete
